import React from "react";
import { useIntl } from 'gatsby-plugin-intl';

import Layout from "../components/Layout";
import SEO from "../components/seo"

import ServicesSection from "../components/ServicesSection"

const Servicos = () => {
  
   const intl = useIntl();

   return (
      <Layout>
         <SEO 
            lang={intl.locale}
            title="Serviços de Reparos"
            keywords={[ `indústria metalúrgica em São Paulo`, `recuperação`, `reparo`, `chaves fim de curso`, `disjuntores`, `contatores`, `chaves elétricas`, `chaves combinadoras`, `fabricação`, `garantia especial`, `projetos`, `produtos especiais` ]}
         />
         <ServicesSection />
      </Layout>
   )
}

export default Servicos