import styled from "styled-components";
import media from "../../styles/breakPoints"
import { FaArrowRight } from "react-icons/fa"

import Section from "../../objects/Section"
import Container from "../../objects/Container"
import TitleSection from "../../objects/TitleSection"

const WrapServices = styled(Section)`
   background-color: ${props => props.theme.color.grayLight};
   flex-direction: column;
   padding: 50px 0;
`

const ServicesContainer = styled(Container)`
   display: block;
   max-width: 992px;
   padding: 30px 16px 0;
/* background-color: yellow; */

   ${media.greaterThan("md")`
      padding: 50px 32px 0;
   `}

`

const Title = styled(TitleSection)`
   font-size: ${props => props.theme.size.small};
   line-height: 2.4rem;
   text-align: left;
   text-transform: uppercase;
   margin-bottom: 2rem;
   &:after {
      content: none;
   }

   ${media.greaterThan("md")`
      margin-bottom: 3rem;
   `}

   ${media.greaterThan("lg")`
      font-size: ${props => props.theme.size.mediumSmall};
      line-height: 3rem;
   `}

`

const ListItems = styled.ul`
   margin: 0;
   padding: 0;
   list-style: none;
   &:not(:last-child) {
      margin-bottom: 2rem;
   }
`

const ListIcon = styled(FaArrowRight)`
   margin-right: -15px;
   transform: translateX(-25px);
   color: ${props => props.theme.color.secondyDark};
`

const Item = styled.li`
   width: 100%;
   font-family: ${props => props.theme.font.secondy};
   font-size: ${props => props.theme.size.verySmall};
   font-weight: normal;
   color: ${props => props.theme.color.grayMedium};
   text-align: left;
   line-height: 2rem;
   margin: 0 0 1rem;
   padding: 0 0 0 25px;

   ${media.greaterThan("md")`
      font-size: ${props => props.theme.size.small};
      line-height: 2.4rem;
   `}

   ${media.greaterThan("lg")`
      font-size: ${props => props.theme.size.mediumSmall};
      line-height: 3rem;
   `}

`

const ButtonDownload = styled.a`
   width: 220px;
   display: block;
   margin: 0 auto;
   background-color: ${props => props.theme.color.primaryDark};
   color: ${props => props.theme.color.grayClear};
   padding: 7px 12px;
   border-radius: 5px;
   border: none;
   text-transform: uppercase;
   font-family: ${props => props.theme.font.secondy};
   font-size: ${props => props.theme.size.verySmall};
   text-align: center;
   font-weight: bold;
   text-decoration: none;
   cursor: pointer;
   transition: all 300ms linear;
   opacity: 0.9;
   &:hover {
      transition: all 300ms linear;
      text-decoration: inherit;
      opacity: 1;
   }

   ${media.greaterThan("sm")`
      font-size: ${props => props.theme.size.small};
   `}

   ${media.greaterThan("lg")`
      font-size: ${props => props.theme.size.mediumSmall};
      width: 260px;
      padding: 10px 20px;
      margin: 0;
   `}
`


export {
   WrapServices,
   ServicesContainer,
   Title,
   ListItems,
   ListIcon,
   Item,
   ButtonDownload
}