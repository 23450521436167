import React from "react";
import { useStaticQuery, graphql } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl';

import TitleSection from "../../objects/TitleSection"

import { WrapServices, 
         ServicesContainer,
         Title,
         ListItems,
         ListIcon,
         Item,
         ButtonDownload } from "./styles"

const ServicesSection = ()  => {

   const intl = useIntl();

   const content = useStaticQuery (
      graphql`
         query {
            pdfCatalaogo: file(relativePath: { 
               eq: "servicos-reparo/pdf01-Catalogo_Servicos-de-reparo-Eletrosil.pdf" }) {
                  publicURL
            }
         }
      `
   )


   return (
      <WrapServices>
         <TitleSection tagAs="h1"
            title={intl.formatMessage({ id: "pageservice.title" })}
         />
         <ServicesContainer>
            <Title 
               title={intl.formatMessage({ id: "pageservice.subtitle" })}
            />
            <ListItems>
               <Item>
                  <ListIcon />
                  {intl.formatMessage({ id: "pageservice.item1" })}
               </Item>
               <Item>
                  <ListIcon />
                  {intl.formatMessage({ id: "pageservice.item2" })}
               </Item>
               <Item>
                  <ListIcon />
                  {intl.formatMessage({ id: "pageservice.item3" })}
               </Item>
               <Item>
                  <ListIcon />
                  {intl.formatMessage({ id: "pageservice.item4" })}
               </Item>
               <Item>
                  <ListIcon />
                  {intl.formatMessage({ id: "pageservice.item5" })}
               </Item>
               <Item>
                  <ListIcon />
                  {intl.formatMessage({ id: "pageservice.item6" })}
               </Item>
               <Item>
                  <ListIcon />
                  {intl.formatMessage({ id: "pageservice.item7" })}
               </Item>
               <Item>
                  <ListIcon />
                  <strong>
                     {intl.formatMessage({ id: "pageservice.item8" })}
                  </strong>
               </Item>
               <Item>
                  <ListIcon />
                  {intl.formatMessage({ id: "pageservice.item9" })}
               </Item>
            </ListItems>
            <ButtonDownload 
               href={content.pdfCatalaogo.publicURL}
               rel="noopener"
               target="_blank">
                  {intl.formatMessage({ id: "pageservice.labelbutton" })}
            </ButtonDownload>      
         </ServicesContainer>
      </WrapServices>
  )
}

export default ServicesSection